<template>
	<section class="settings-container" :style="style"><slot></slot></section>
</template>

<script>
export default {
	props: {
		window: {
			type: String,
			default: '100%'
		}
	},
	computed: {
		style() {
			return `width: ${this.window};`;
		}
	}
};
</script>

<style lang="scss">
.settings-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	border-radius: 10px;
	background-color: $background-color;
	padding: 2rem 2rem;
}
</style>
